<template>
  <div class="login-container">
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable */

import {
  QSpinnerClock
} from 'quasar'
export default {
  name: 'sso',
  data() {
    return {
      userid: '',
      redirect: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '40%',
        isFull: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
  },
  created() {
    // window.addEventListener('hashchange', this.afterQRScan)
  },
  beforeMount() {
    this.init();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    init() {
      this.$q.loading.show({
        spinner: QSpinnerClock,
        spinnerColor: 'white',
        spinnerSize: 250,
        backgroundColor: 'blue',
        message: '로그인 중 입니다.<br/>잠시만 기다려주세요.',
        messageColor: 'white'
      })

      if (this.$route.query) {
        if (this.$route.query.cn) {
          this.loginId = this.$route.query.cn;
        }
        
        if (this.$route.query.redirect) {
          this.redirect = this.$route.query.redirect;
        }
      }

      if (this.loginId) {
        this.$store
          .dispatch('sso', { loginId: this.loginId })
          .then(() => {
            this.loadingHide();
            this.$router.push({ path: this.redirect || '/' }).catch(() => { });
          })
          .catch(error => {
            this.loadingHide();
            if (error && error.response && error.response.data && error.response.data.returnCode && error.response.data.returnCode.split('|').length > 1) {
              this.changePassword(error.response.data.message, error.response.data.returnCode.split('|')[1]);
              _resolve(false);
            } else {
              this.$store
                .dispatch('LogOut')
                .then(() => {
                  this.$router.push({ path: '/ssoFail' });
                })
                .catch(err => {
                  this.$router.push({ path: '/ssoFail' });
                });
              this.$comm.movePage(this.$router, '/404');
            }
          });
      } else {
        this.loadingHide();
        this.$router.push({ path: '/ssoFail' });
      }
    },
    changePassword(message, returnUserId) {
      this.popupOptions.title = '비밀번호 변경 안내(SWP접속 전용 비밀번호)'; // 비밀번호 변경
      this.popupOptions.param = {
        loginId: returnUserId,
        message: message
      }
      this.popupOptions.target = () => import(`${'./initChangePw.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$router.push('/');
    },
    loadingHide() {
      this.$nextTick(() => {
        this.$q.loading.hide()
      });
    },
  },
};
</script>